import React, { useEffect } from 'react'
import { Route, Switch, Redirect, useLocation } from 'react-router-dom'
import {
    DetectItems,
    DeepTagging,
} from '../src/screens'
import Layout from './components/Layout'
import ReactGA from 'react-ga'

const App: React.FC<ServerData> = ({
    lang,
    textSearchResult,
    imageSearchResult
}) => {
    if (typeof window !== 'undefined' && window['GA_CODE']) {
        ReactGA.initialize(window['GA_CODE'])
    }
    useEffect(() => {
        const fonts = document.getElementById('webfonts')
        if (fonts) {
            fonts.removeAttribute('disabled')
        }
    })

    const { pathname, hash, key } = useLocation()
    useEffect(() => {
        // if not a hash link, scroll to top
        if (hash === '') {
            window.scrollTo(0, 0)
        } else {
            setTimeout(() => {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView()
                }
            }, 0)
        }
    }, [pathname, hash]) // do this on route change

    return (
        <Layout
            lang={lang}
            textSearchResult={textSearchResult}
            imageSearchResult={imageSearchResult}
        >
            <Switch>
                <Route exact={true} path="/item-detection" component={DetectItems} />
                <Route exact={true} path="/deep-tagging" component={DeepTagging} />
                <Route
                    exact={true}
                    path="/deep-tagging-2"
                    render={(props) => (
                        <DeepTagging {...props} generateDescription={true} />
                    )}
                />
                <Route render={(props) => <Redirect {...props} to="/item-detection" />} />
            </Switch>
        </Layout>
    )
}

export default App
