import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import { PrimaryCta } from '../Button'
import AppContext from '../../helpers/AppContext'
import ImagePicker from '../ImagePicker'
import Image from '../Image'
import TextLine from '../TextLine'
import { detectItems, DetectItemsResponse } from '../../helpers/bacillusClient'
import axios from 'axios'
import ApiResponse from '../ApiResponse'

export interface Props {
    className?: string
    primary?: boolean
}

async function srcToBlob (src: string) {
    const response = await axios({
        method: 'get',
        url: src,
        responseType: 'blob'
    })

    return response.data
}

const ItemDetection: React.FC<Props> = (props) => {
    const { className } = props
    const ctx = useContext(AppContext)
    const [detecting, setDetecting] = useState(false)
    const [imgSrc, setImgSrc] = useState<string>()
    const [detectedItemsResp, setDetectedItemsResp] = useState<DetectItemsResponse>()

    useEffect(() => {
        if (!imgSrc) {
            return
        }

        ctx.openDrawer(getImgViewer(imgSrc))
    }, [imgSrc, detecting, detectedItemsResp])

    const getImgViewer = (src: string) => {
        let viewerFooter = (
            <div style={{marginTop: '10px'}}>
                <PrimaryCta icon="Check" full={true} onClick={getDetectedItems} />
            </div>
        )
        if (detecting) {
            viewerFooter = (
                <>
                    <TextLine
                        text="Detecting Items ..."
                        type="subtitle"
                        center={true}
                    />
                </>
            )
        } else if (detectedItemsResp) {
            viewerFooter = (
                <div style={{marginTop: '10px', marginBottom: '10px'}}>
                    <TextLine text="Detected Items" type="subtitle"/>
                    {detectedItemsResp.detected_items.map((item) => (
                        <div style={{marginBottom: '10px'}}>
                            <div style={{marginBottom: '3px'}}>
                                <TextLine text="name:" type="caption" inline={true} bold={true}/>
                                &nbsp;&nbsp;&nbsp;
                                <TextLine text={item.name} type="caption" inline={true}/>
                            </div>
                            <div style={{marginBottom: '3px'}}>
                                <TextLine text="category:" type="caption" inline={true} bold={true}/>
                                &nbsp;&nbsp;&nbsp;
                                <TextLine text={item.category} type="caption" inline={true}/>
                            </div>
                            <div style={{marginBottom: '3px'}}>
                                <TextLine text="confidence:" type="caption" inline={true} bold={true}/>
                                &nbsp;&nbsp;&nbsp;
                                <TextLine text={`${item.detection_confidence}`} type="caption" inline={true}/>
                            </div>
                        </div>
                    ))}
                </div>
            )
        }

        return (
            <div>
                <div style={{ height: detectedItemsResp ? '20vh' : '60vh' }}>
                    <Image
                        className="expandedQueryImage"
                        height="100%"
                        width="100%"
                        src={src}
                        alt="query image"
                        fit="contain"
                        setCors={false}
                    />
                </div>
                {viewerFooter}
            </div>
        )
    }

    const getDetectedItems = async () => {
        if (!imgSrc) {
            return
        }

        setDetecting(true)
        const imgBlob = await srcToBlob(imgSrc)
        try {
            const resp = await detectItems(imgBlob)
            setDetectedItemsResp(resp)
        } catch (error) {
            ctx.setToastMessage({
                text: 'This request failed for some reason. Please try again',
                type: 'error',
                timeout: 7000
            })
        } finally {
            setDetecting(false)
        }
    }

    const onImageAdded = async (src: string) => {
        setImgSrc(src)
        setDetectedItemsResp(undefined)
    }

    return (
        <div className={className}>
            <div className="container">
                <div className="display">
                    <h1 className={'hero-text'}>Item Detection API Demo</h1>
                </div>
                <ImagePicker onImageAdded={onImageAdded} />
            </div>
            {detectedItemsResp ? <ApiResponse responseText={JSON.stringify({ data: detectedItemsResp }, null, 2)} /> : null}
        </div>
    )
}

const StyledItemDetection = styled(ItemDetection)((props) => {
    return {
        // backgroundColor: 'white',
        marginTop: -16, // reset global padding-top
        ['.container']: {
            margin: 'auto',
            padding: `${theme.space.large}`,
            maxWidth: 700,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            zIndex: 0,
            [`${theme.media.largeScreenBreakpoint}`]: {
                padding: `${theme.space.largest} 0`
            },
            ['.display']: { marginBottom: theme.space.larger },
            ['.hero-text']: {
                fontFamily: 'Inter, sans-serif',
                fontSize: 30,
                fontWeight: 700,
                lineHeight: '29px',
                [`${theme.media.largeScreenBreakpoint}`]: {
                    lineHeight: '58px',
                    fontSize: 48
                }
            }
        }
    }
})
export default StyledItemDetection
