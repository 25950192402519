import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga'
import 'firebase/auth'
import theme from '../../theming'
import BottomBar from '../BottomBar'
import TopBar from '../TopBar'
import BottomDrawer from '../BottomDrawer'
import SideDrawer from '../SideDrawer'
import Footer from '../Footer'
import Toast from '../Toast'
import Loading from '../Loading'
import AppContext from '../../helpers/AppContext'
import ScreenContainer from '../SreenContainer'
import * as layoutHelper from './helper'
import getExploreItems from '../../helpers/exploreItems'

export interface Props extends ServerData {
    className?: string
    children: React.ReactElement
}

layoutHelper.initializeFirebase()

const Layout: React.FC<Props> = (props) => {
    const { children, className } = props

    const appRoute = useLocation()
    const [
        onInit,
        ctx,
        isDesktop,
        storage
    ] = layoutHelper.getLayoutElements(props)

    useEffect(() => {
        if (appRoute.pathname === '/empty') {
            return
        }

        // when a new route is visited, we empty the drawer
        ctx.openDrawer(null)
        ReactGA.pageview(appRoute.pathname)
    }, [appRoute])

    useEffect(() => {
        if (!ctx.isDesktop) {
            Array.from(window.document.getElementsByClassName(
                'drift-conductor-item'
            ) as HTMLCollectionOf<HTMLElement>).map((el) => {
                el.style.visibility = 'hidden'
                el.style.display = 'none'
            })
        }
    })

    useEffect(() => {
        onInit()
    }, [])

    useEffect(() => {
        // prefetching user related endpoints to keep them warm
        // and to improve experience
        if (ctx.currentUser) {
            storage.getLikedProducts(ctx)
            storage.getRecentProducts(ctx)
            storage.getRecentSearches(ctx)
        }
    }, [storage])

    return (
        <div className={className}>
            <AppContext.Provider value={ctx}>
                <TopBar layer="appShell" />
                <ScreenContainer>
                    <div className="appContent">{children}</div>
                </ScreenContainer>
                {isDesktop ? <SideDrawer /> : <BottomDrawer />}
                <Toast />
                <Loading />
            </AppContext.Provider>
        </div>
    )
}

const StyledLayout = styled(Layout)((_props) => {
    return {
        background: theme.color.background,
        ['.appContent']: {
            zIndex: theme.layer.body,
            padding: `${theme.space.small}`
        },
        ['a']: {
            textDecoration: 'none'
        }
    }
})
export default StyledLayout
