import React from 'react'
import TextLine from '../TextLine'

export interface Props {
    className?: string
    responseText: string
}


const ApiResponse: React.FC<Props> = (props) => {
    const { className, responseText } = props

    return (
        <div className={className}>
            <TextLine text="API Response" type="subtitle" />
            <div
                style={{
                    maxHeight: '400px',
                    overflowY: 'scroll',
                    background: '#444',
                    color: 'white',
                    padding: '10px',
                    borderRadius: '5px'
                }}
            >
                <pre>{responseText}</pre>
            </div>
        </div>
    )
}

export default ApiResponse
