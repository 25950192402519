import React from 'react'
import ItemDetection from '../../components/ItemDetection'

const DetectItems: React.FC = () => {
    return (
        <>
            <ItemDetection />
        </>
    )
}

export default DetectItems
