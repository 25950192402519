import React from 'react'
import ImageDeepTagging from '../../components/ImageDeepTagging'

interface Props {
    generateDescription?: boolean
}

const DeepTagging: React.FC<Props> = (props) => {
    return (
        <>
            <ImageDeepTagging genereatDescription={props.generateDescription} />
        </>
    )
}

export default DeepTagging
