import React, { useContext, useState, useEffect } from 'react'
import styled from 'styled-components'
import theme from '../../theming'
import { PrimaryCta } from '../Button'
import AppContext from '../../helpers/AppContext'
import ImagePicker from '../ImagePicker'
import Image from '../Image'
import TextLine from '../TextLine'
import { getTagging, ImageTaggingResponse } from '../../helpers/bacillusClient'
import axios from 'axios'
import ApiResponse from '../ApiResponse'

const API_URL_WITH_DESCRIPTION = 'https://bacillus-api-global-text-search-vs6o6hcumq-uc.a.run.app/v1/detection/tags'

export interface Props {
    className?: string
    primary?: boolean
    genereatDescription?: boolean
}

async function srcToBlob (src: string) {
    const response = await axios({
        method: 'get',
        url: src,
        responseType: 'blob'
    })

    return response.data
}

const ImageDeepTagging: React.FC<Props> = (props) => {
    const { className } = props
    const ctx = useContext(AppContext)
    const [gettingTags, setGettingTags] = useState(false)
    const [imgSrc, setImgSrc] = useState<string>()
    const [tagsResponse, setTagsResponse] = useState<ImageTaggingResponse>()

    useEffect(() => {
        if (!imgSrc) {
            return
        }

        ctx.openDrawer(getImgViewer(imgSrc))
    }, [imgSrc, gettingTags, tagsResponse])

    const getImgViewer = (src: string) => {
        let viewerFooter = (
            <div style={{marginTop: '10px'}}>
                <PrimaryCta icon="Check" full={true} onClick={getTags} />
            </div>
        )
        if (gettingTags) {
            viewerFooter = (
                <>
                    <TextLine
                        text="Fetching Tags ..."
                        type="subtitle"
                        center={true}
                    />
                </>
            )
        } else if (tagsResponse) {
            viewerFooter = (
                <div style={{marginTop: '10px', marginBottom: '10px'}}>
                    <TextLine text="Colors" type="subtitle"/>
                    {tagsResponse.colors.map((color) => (
                        <div style={{background: `#${color.hex_code}`, width: '20px', height: '20px', marginRight: '10px', display: 'inline-block'}}></div>
                    ))}
                    <TextLine text="Labels" type="subtitle"/>
                    {tagsResponse.labels.map((keyword) => (
                        <div style={{marginBottom: '10px'}}>
                            <TextLine text={`${keyword.classification}:`} type="caption" inline={true} bold={true}/>
                            &nbsp;&nbsp;&nbsp;
                            <TextLine text={keyword.name} type="caption" inline={true}/>
                        </div>
                    ))}

                    {tagsResponse.description && (<div style={{marginBottom: '10px'}}><TextLine text="Description" type="subtitle"/><TextLine text={tagsResponse.description} type="caption"/></div>)}
                </div>
            )
        }

        return (
            <div>
                <div style={{ height: tagsResponse ? '20vh' : '60vh' }}>
                    <Image
                        className="expandedQueryImage"
                        height="100%"
                        width="100%"
                        src={src}
                        alt="query image"
                        fit="contain"
                        setCors={false}
                    />
                </div>
                {viewerFooter}
            </div>
        )
    }

    const getTags = async () => {
        if (!imgSrc) {
            return
        }

        setGettingTags(true)
        const imgBlob = await srcToBlob(imgSrc)
        try {
            const apiUrl = props.genereatDescription ? API_URL_WITH_DESCRIPTION : undefined
            const resp = await getTagging(imgBlob, apiUrl)
            setTagsResponse(resp)
        } catch (error) {
            ctx.setToastMessage({
                text: 'Request failed because image is too large or format is unsupported',
                type: 'error',
                timeout: 7000
            })
        } finally {
            setGettingTags(false)
        }
    }

    const onImageAdded = async (src: string) => {
        setImgSrc(src)
        setTagsResponse(undefined)
    }

    return (
        <div className={className}>
            <div className="container">
                <div className="display">
                    <h1 className={'hero-text'}>Apparel Auto Tagging Demo</h1>
                </div>
                <ImagePicker onImageAdded={onImageAdded} />
            </div>
            {tagsResponse ? <ApiResponse responseText={JSON.stringify({ data: tagsResponse }, null, 2)} /> : null}
        </div>
    )
}

const StyledImageDeepTagging = styled(ImageDeepTagging)((props) => {
    return {
        // backgroundColor: 'white',
        marginTop: -16, // reset global padding-top
        ['.container']: {
            margin: 'auto',
            padding: `${theme.space.large}`,
            maxWidth: 700,
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
            position: 'relative',
            zIndex: 0,
            [`${theme.media.largeScreenBreakpoint}`]: {
                padding: `${theme.space.largest} 0`
            },
            ['.display']: { marginBottom: theme.space.larger },
            ['.hero-text']: {
                fontFamily: 'Inter, sans-serif',
                fontSize: 30,
                fontWeight: 700,
                lineHeight: '29px',
                [`${theme.media.largeScreenBreakpoint}`]: {
                    lineHeight: '58px',
                    fontSize: 48
                }
            }
        }
    }
})
export default StyledImageDeepTagging
